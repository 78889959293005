import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['pandemicSiblings1', 'pandemicSiblings2', 'pandemicSiblings3', 'pandemicSiblings4', 'pandemicSiblings5'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SingleImage = makeShortcode("SingleImage");
const ImageAccordion = makeShortcode("ImageAccordion");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "sibling-conflict"
    }}>{`Sibling conflict`}</h2>
    <p>{`If you have multiple children at home, chances are there will be conflict between them, especially during the pandemic. Dealing with sibling conflict can sometimes feel like you’re extinguishing a fire or providing first aid. On top of the ground rules we’ve just discussed, it can help to have a toolkit ready.`}</p>
    <p>{`Here are some extra tools you can use to prevent sibling conflict, and to manage it constructively when it does happen.`}</p>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m5/14-toolkit.png" alt="Toolkit" mdxType="SingleImage" />
    <ImageAccordion id="pandemicSiblings1" index="1" title="Praise & reward" description={<div>
      Notice when your children are getting along well or working together, and praise or reward them.
      <br />
      “Well done!” “Good work” “I’m proud of you both” “Wow, look what you can achieve together” “Aren’t you a great
      team”
    </div>} hasNumber={true} mdxType="ImageAccordion" />
    <ImageAccordion id="pandemicSiblings2" index="2" title="Rules" description={<div>
      Create rules about sibling conflict and how this will be managed. Involve everyone, and make sure you have these
      conversations when everyone is calm.
    </div>} hasNumber={true} mdxType="ImageAccordion" />
    <ImageAccordion id="pandemicSiblings3" index="3" title="Fun times together" description={<div>
      Encourage positive shared experiences between siblings -- for example, family walks, movie nights, fun activities
      that everyone enjoys.
    </div>} hasNumber={true} mdxType="ImageAccordion" />
    <ImageAccordion id="pandemicSiblings4" index="4" title="Discuss" description={<div>
      During the pandemic, conflict might arise between siblings if they are affected differently (e.g. different local
      regulations based on age, different access to screens due to online learning, different responsibilities because
      of age). If this is the case, have an open conversation with all children about it. Having everyone on the same
      page about the reasons behind rules can help to minimise conflict.
    </div>} hasNumber={true} mdxType="ImageAccordion" />
    <ImageAccordion id="pandemicSiblings5" index="5" title="Lessons from the conflict" description={<div>
      After everyone has calmed down, try to understand what has caused the conflict. Encourage siblings to calmly
      reflect on what happened, work out what should happen next, and how they could minimise conflict in the future.
    </div>} hasNumber={true} mdxType="ImageAccordion" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      